<template>
  <div>
    <portfolio-create-page></portfolio-create-page>
  </div>
</template>

<script>
import PortfolioCreatePage from './PortfolioCreatePage.vue';

export default {
  components: {
    PortfolioCreatePage,
  },

  data() {
    return {};
  },
};
</script>
